import React, { useEffect } from "react";
import DiyContent from "../component/DiyContent";
import { Box } from "@mui/material";
import CustomSeparator from "../component/Header/CustomSeparator";
import CoachingContent from "../component/CoachingContent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormStore } from "../Hooks/store/formStore";
import logAmplitudeEvent from "../utils/logAmplitudeEvent";

const Help = () => {
  useEffect(() => {
    logAmplitudeEvent({
      eventName: "CaloriePoll",
    });
  }, []);

  const { height, weight } = useFormStore((state) => ({
    height: state.height,
    weight: state.weight,
  }));
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!height || !weight) {
      navigate("/");
    }
  }, [height, weight, navigate]);

  const [searchParams] = useSearchParams();
  const content = searchParams.get("content");

  return (
    <Box
      sx={{
        pb: 2.5,
        px: 2.5,
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          py: 2.5,
        }}
      >
        <CustomSeparator />
      </Box>
      {content === "coaching" ? <CoachingContent /> : <DiyContent />}
    </Box>
  );
};

export default Help;
