import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/index";
import Routes from "./Routes";
import "./App.css";
import { useEffect } from "react";
import { affordableCountryCodeList } from "./constant/affordableCountryCodeList";
import logAmplitudeEvent from "./utils/logAmplitudeEvent";

function App() {
  useEffect(() => {
    // amplitude initialization
    var amplitudeApiKey =
      window.location.origin === "https://calories.coachviva.com"
        ? "2d1a107e5cca5f281874f3094cbe3bd6"
        : "88c17c84bb21a644e8d051f1d1f24eed";

    const sessionReplayTracking = window.sessionReplay.plugin({
      forceSessionTracking: true,
      sampleRate: 1,
    });
    window.amplitude.add(sessionReplayTracking);
    window.amplitude.init(amplitudeApiKey, {
      autocapture: true,
      sessionTrackingEnabled: true,
    });

    // check availability status based on geo location
    async function checkUserCallAffordability() {
      const searchParams = new URLSearchParams(window.location.search);
      const utmObject = {};
      searchParams.forEach((value, key) => {
        if (key.toLowerCase().startsWith("utm")) {
          utmObject[key.toLowerCase()] = value;
        }
      });

      try {
        const response = await fetch(
          "https://us-central1-body-fat-calc-test.cloudfunctions.net/getIpInSight"
        );
        const data = await response.json();
        sessionStorage.setItem("isAffordableCountry", false);
        if (data?.dial_code) {
          const isAffordableCountry = affordableCountryCodeList.includes(
            data.dial_code
          );
          sessionStorage.setItem("isAffordableCountry", isAffordableCountry);

          logAmplitudeEvent({
            userProperties: {
              ...utmObject,
              Affordability: isAffordableCountry ? "Yes" : "No",
            },
          });
        }
      } catch (error) {
        logAmplitudeEvent({
          eventName: "CalorieStats",
          userProperties: {
            ...utmObject,
          },
        });
        console.error("Error fetching data:", error);
      }
    }
    checkUserCallAffordability();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
